<template>
<v-modal width="22.5em"
  v-bind="{ modelValue }"
  @close="onClose()">

  <template #content>
    <div v-if="notification.icon"
      class="material-symbols v-modal__icon" 
      :style="{ color: course.color }">
      {{ notification.icon }}
    </div>
    <div class="modal-notification__header">
      <div v-if="notification.title" 
        class="v-modal__title"
        style="text-align:center">
        {{ notification.title }}
      </div>
    </div>
    <div v-if="notification.text" 
      class="v-modal__text"
      style="text-align:center">
      {{ notification.text }}
    </div>
  </template>

  <template #buttons>
    <button class="button-primary"
      v-if="notification.url"
      @click="navigatePage()">
      Перейти
    </button>
  </template>

</v-modal>
</template>

<script>
import instance from '@/api/instance'
import store from '@/store'
import router from '@/router'
import vModal from './v-modal.vue'
export default {
  components: { vModal },
  emits: [
    'update:modelValue',
    'delete:notification',
    'close',
  ],
  async mounted() {
    if (!this.notification.isReadOnRedirect && !this.notification.isSeen) {
      await this.markAsRead()
    }
  },
  props: {
    modelValue: { type: Boolean, required: false, default: false },
    subject: { type: Object, required: true, default: {} },
    course: { type: Object, required: true, default: {} },
    notification: { type: Object, required: true, default: {} },
  },
  methods: {
    onClose() {
      this.$emit('close')
    },
    async navigatePage() {
      if (!['task_check_required'].includes(this.type) && this.notification.isReadOnRedirect) {
        await this.markAsRead()
      }
      const url = this.notification.url
        .replace('{subjectId}', this.subject._id)
        .replace('{userId}', this.notification.createdBy)
        .replace('{courseId}', this.notification.courseId)
        .replace('{lessonId}', this.notification.lessonId)
      this.$nextTick(_ => router.push(`/${url}`))
      this.onClose()
    },
    async markAsRead() {
      await instance.post('/notifications/update-status', {
        notificationIds: this.notification.notificationIds,
        isSeen: true,
      })
      store.commit('updateArrayOfObjects', {
        arrayName: 'notifications',
        fieldName: '_id',
        fieldValue: this.notification._id,
        payload: { isSeen: true },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-notification {
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .7em;
  }
  &__info {
    text-align: center;
  }
}
</style>
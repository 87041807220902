import { createStore } from 'vuex'
import instance from '@/api/instance'
import themes from '@/styles/themes.js'
import Task from '@/classes/Task'

export default createStore({

  state: () => ({
    isBrowserCompatible: true,
    isPageUnloadPrevented: false,
    theme: null,
    zoom: localStorage.getItem('zoom') ?? 1,
    loader: {
      isVisible: false,
      bgColor: '#0003'
    },
    pagesTotal: 0,
    role: null,
    subjectId: null,
    mediafiles: [],
    triggers: [],
    notifications: [],
    templates: [],
    reports: [],
    jobs: [],
    tip: {},
    tips: [],
    glossary: [],
    certificate: {},
    subjectsList: [],
    subjects: [],
    subject: {},
    coursesList: [],
    courses: [],
    course: {},
    modules: [],
    lessonsList: [],
    lessons: [],
    lesson: {},
    tasks: [],
    groupsList: [],
    groups: [],
    group: {},
    students: [],
    student: {},
    persons: [],
    connections: [],
    connection: {},
    stats: {},
    rating: {
      general: {},
      students: [],
    },
    userdata: {
      _id: "Guest",
      avatar: false,
      email: undefined,
      username: "Гість",
      settings: {},
    },
    settings: {
      telegram: undefined,
      instagram: undefined,
      dialoguesMode: 'messenger'
    },
    subjectOptions: {
      templates: {
        registration: undefined,
      },
    },
    courseOptions: {
      isLinear: false,
      hasRating: false,
      hasDistribution: false,
      hasCertification: false,
      hasDeadline: false,
      hasSandbox: false,
      hasTips: false,
      certificateTemplate: undefined,
      certificationType: undefined,
      certificationValue: undefined,
      certificateBackground: false,
      certificateBackgroundV: 0,
      examIds: [],
      topics: [],
      deadline: 1,
      templates: {
        deadlineWarning: undefined,
        deadlineReached: undefined,
      },
    },
    lessonOptions: {
      attemptsMax: 1,
      isLinear: false,
      isCumulative: false,
      isRatable: false,
      isUserSelectDisabled: false,
      isAssessment: false,
      connectionId: undefined,
      duration: '0 год 00 хв ',
    },
  }),

  mutations: {
    preventUnload(state, payload) {
      state.isPageUnloadPrevented = payload
    },
    setTheme(state, theme) {
      state.theme = theme
      localStorage.setItem('theme', theme)
      Object.entries(themes[theme])?.forEach(([k, v]) => {
        document.documentElement.style.setProperty(k, v)
      })
    },
    setZoom(state, zoom) {
      zoom = Math.min(Math.max(zoom, .5), 2)
      localStorage.setItem('zoom', zoom)
      state.zoom = zoom
    },
    showLoader(state, payload) {
      if (typeof payload == 'boolean') state.loader.isVisible = payload
      if (typeof payload == 'object') {
        state.loader.isVisible = true
        state.loader.bgColor = payload.bgColor
      }
    },
    updateState(state, payload) {
      for (const [k, v] of Object.entries(payload)) {
        state[k] = v
      }
    },
    updateArrayOfObjects(state, { arrayName, fieldName, fieldValue, payload }) {
      Object.assign(state[arrayName].find(e => e[fieldName] === fieldValue), payload)
    },
    updateArrayItem(state, { arrayName, value, index }) {
      if (index >= 0) state[arrayName][index] = value
      else state[arrayName]?.push(value)
    },
    deleteArrayItem(state, { arrayName, index }) {
      state[arrayName]?.splice(index, 1)
    },
    setRole(state, payload) {
      state.role = payload
    },
    setSubjectId(state, payload) {
      state.subjectId = payload
    },
    updateUserdata(state, { subject, user }) {
      if (subject) state.subject = subject
      Object.assign(state.userdata, user)
    },
    setQuiz(state, { course, lesson, tasks, persons, reports, connections, glossary, tips, mediafiles }) {
      state.course = course
      state.lesson = lesson
      state.persons = persons
      state.reports = reports
      state.tips = tips
      state.connections = connections
      state.mediafiles = mediafiles
      if (tasks) state.tasks = tasks.map(task => new Task(lesson, task, glossary))
    },
    updateLesson(state, lessonData) {
      state.lesson = Object.assign(state.lesson, lessonData)
    },
    updateTask(state, taskData) {
      const taskId = taskData._id
      const taskIndex = state.tasks?.findIndex(e => e._id == taskId)
      state.tasks[taskIndex] = new Task(state.lesson, {
        ...state.tasks[taskIndex], 
        ...taskData
      })
    },
    setTask(state, taskData) {
      const { index } = taskData
      state.tasks[index] = taskData
    },
    pushTask(state, taskData) {
      const { index } = taskData
      state.tasks.splice(index, 0, new Task(state.lesson, taskData))
    },
    deleteTask(state, { index }) {
      state.tasks.splice(index, 1)
    },
    rearrangeItems(state, { arrayName, index1, index2 }) {
      const temp1 = state[arrayName][index1]
      state[arrayName][index1] = state[arrayName][index2]
      state[arrayName][index2] = temp1
    },
    setAuthorLessonSettings(state, { course, lesson, persons }) {
      if (!lesson.personIds) lesson.personIds = []
      state.course = course
      state.lesson = lesson
      state.persons = persons
    },
  },

  actions: {
    async login({ dispatch }, { username, password }) {
      let { data: { accessToken } } = await instance.post(`/login`, { username, password })
      localStorage.setItem('accessToken', accessToken)
      dispatch('getUserdata')
    },
    async getAgenda({ commit, state }, { start, end }) {
      const { data } = await instance.get(`/${state.role}/agenda`, { params: { start, end }})
      commit('updateState', data)
    },
    async getUserdata({ commit, state }) {
      const { data } = await instance.get(`/${state.role}/userdata`)
      commit('updateUserdata', data)
      window.faro?.api?.setUser({
        _id: data.user._id,
        email: data.user.email,
        attributes: { role: state.role },
      })
    },
    async getSubjects({ commit }) {
      const { data } = await instance.get('/subjects')
      commit('updateState', data)
    },
    async getSubject({ commit, state }) {
      if (!state.subjectId) return
      const { data } = await instance.get(`/${state.role}/subject`)
      commit('updateState', data)
    },
    async getSubjectSettings({ commit, state }) {
      const { data } = await instance.get(`/${state.role}/subject-settings`)
      commit('updateState', data)
    },
    async getCourses({ commit, state }) {
      const { data } = await instance.get(`/${state.role}/courses`)
      commit('updateState', data)
    },
    async getLessons({ commit, state }, { courseId }) {
      const { data } = await instance.get(`/${state.role}/lessons/${courseId}`)
      commit('updateState', data)
    },
    async getConnections({ commit }, { courseId }) {
      const { data } = await instance.get(`/connections/${courseId}`)
      commit('updateState', data)
    },
    async getCourseInfo({ commit, state }, { courseId }) {
      const { data } = await instance.get(`/${state.role}/course-info/${courseId}`)
      commit('updateState', data)
    },
    async getTasks({ commit, state }, { lessonId }) {
      const { data } = await instance.get(`/${state.role}/tasks/${lessonId}`)
      commit('setQuiz', data)
    },
    async getCourseFiles({ commit, state }, { courseId }) {
      const { data } = await instance.get(`/${state.role}/course-files/${courseId}`)
      commit('updateState', data)
    },
    async getGroup({ commit, state }, { groupId }) {
      const { data } = await instance.get(`/${state.role}/group/${groupId}`)
      commit('updateState', data)
    },
    async getGroups({ commit, state }, { page, limit, filters }) {
      const { data } = await instance.post(`/${state.role}/groups`, { filters }, { params: { page, limit }})
      commit('updateState', data)
    },
    async getGroupsInfo({ commit, state }, { page, limit, filters }) {
      const { data } = await instance.post(`/${state.role}/groups-info`, { filters }, { params: { page, limit }})
      commit('updateState', data)
    },
    async getGroupsList({ commit, state }, { filters }) {
      const { data } = await instance.post(`/${state.role}/groups-list`, { filters })
      commit('updateState', data)
    },
    async getNotes({ commit }, { courseId }) {
      const { data } = await instance.get(`/student/notes/${courseId}`)
      commit('updateState', data)
    },
    async getTips({ commit, state }, { courseId }) {
      const { data } = await instance.get(`/${state.role}/tips/${courseId}`)
      commit('updateState', data)
    },
    async getTip({ commit, state }, { tipId }) {
      const { data } = await instance.get(`/${state.role}/tip/${tipId}`)
      commit('updateState', data)
    },
    async getStudents({ commit, state }, { page, limit, sortBy, sortOrder, filters }) {
      const { data } = await instance.post(`/${state.role}/students`, { filters }, { params: { page, limit, sortBy, sortOrder }})
      commit('updateState', data)
    },
    async getRoles({ commit }, { role, page, limit, sortBy, sortOrder, filters }) {
      const { data } = await instance.post(`/admin/roles/${role}`, { filters }, { params: { page, limit, sortBy, sortOrder }})
      commit('updateState', data)
    },
    async getStudent({ commit, state }) {
      const { data } = await instance.get(`/${state.role}/student`)
      commit('updateState', data)
    },
    async getRating({ commit }, { limit }) {
      const { data } = await instance.get(`/student/rating/${limit}`)
      commit('updateState', data)
    },
    async getRewards({ commit }) {
      const { data } = await instance.get('/stats/rewards')
      commit('updateState', data)
    },
    async getStatsStudent({ commit }, { userId, courseId }) {
      const { data } = await instance.get(`/stats/student/${courseId ?? ''}`, { params: { userId } })
      commit('updateState', data)
    },
    async getStatsSubject({ commit }) {
      const { data } = await instance.get(`/stats/subject`)
      commit('updateState', data)
    },
    async getStatsCourseLessons({ commit, state }, { courseId, sortBy, sortOrder, filters }) {
      const { data } = await instance.post(`/${state.role}/course-stats/lessons/${courseId}`, { filters }, { params: { sortBy, sortOrder }})
      commit('updateState', data)
    },
    async getStatsCourseStudents({ commit, state }, { courseId, page, limit, sortBy, sortOrder, filters }) {
      const { data } = await instance.post(`/${state.role}/course-stats/students/${courseId}`, { filters }, { params: { page, limit, sortBy, sortOrder } })
      commit('updateState', data)
    },
    async getStatsCourseFunnels({ commit, state }, { courseId, groupId, granularity }) {
      const { data } = await instance.post(`/${state.role}/course-stats/funnel/${courseId}`, {}, { params: { groupId, granularity } })
      commit('updateState', data)
    },
    async getStatsLesson({ commit, state }, { lessonId, filters }) {
      const { data } = await instance.post(`/${state.role}/lesson-stats/${lessonId}`, { filters })
      commit('updateState', data)
    },
    async checkTask({ commit }, taskData) {
      const { data } = await instance.post('/student/task/check', taskData)
      const { lesson, task, taskNext, user } = data
      commit('updateLesson', lesson)
      commit('updateTask', task)
      if (taskNext) commit('pushTask', taskNext)
      if (user) commit('updateUserdata', { user })
    },
    async checkTaskTeacher(state, taskData) {
      await instance.post('/teacher/task/check', taskData)
      const notification = state.state.notifications.find(e => e.type=='task_check_required' && e.lessonId==taskData.lessonId)
      if (notification) notification.count -= 1
    },
    async checkLesson({ commit }, lessonData) {
      const { data: { user } } = await instance.post('/student/lesson/check', lessonData)
      if (user) commit('updateUserdata', { user })
    },
    async getCertificate({ commit }, { userId, courseId }) {
      const { data } = await instance.get(`/certificate/${courseId}`, { params: { userId } })
      commit('updateState', data)
    },
  }

})
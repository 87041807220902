import { reactive, computed } from 'vue'

export default {
  
  install: (app, options) => {

    const w = window.innerWidth
    let screen = reactive({
      width: w,
      isMobile: w<601,
      isTablet: w>600 && w<960,
      isDesktop: w>959
    })

    const onResize = _ => {
      const w = window.innerWidth
      screen.width = w
      screen.isMobile = w<601
      screen.isTablet = w>600 && w<960
      screen.isDesktop = w>959
    }
    const onScroll = _ => {
      screen.scrollY = window.scrollY
    }

    window.addEventListener('resize', onResize)
    window.addEventListener('scroll', onScroll)

    app.config.globalProperties.$screen = screen

  }

}
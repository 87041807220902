import store from '../store.js'

export default class Task {
  
  constructor(lesson, task, glossary) {

    Object.assign(this, task)
    this.wasEmpty = task.isEmpty
    this.attempt = task.attempt ?? null
    this.percentage = task.weight>0 ? Math.round(+task.score / +task.weight * 100) : 100
    this.isCompleted = this.isAnswered && task.attempt >=0 
    this.isFinished = (this.isCompleted && this.percentage==100) || (this.attempt >= task.settings?.attemptsMax)
    this.isActivity = ['choice','pairs','multiple','multiple_pairs','short_answer','short_answers','gaps','essay','file_upload'].includes(task.type)
    this.isManual = ['essay','file_upload'].includes(task.type)
    if (task.url) this.provider = task.url?.includes('/media') ? 'dash' : 'youtube'

    try {
      if (glossary?.length) {
        const regexGlossary = this.formRegex(glossary.map(e => e.term))
        if (glossary?.length) this.deepReplace(task, 'text', str => str.replaceAll(regexGlossary, word => {
          return `<span class='tooltip' tooltip="${glossary.find(e => word.match(new RegExp(`${e.term}`, 'i')))?.definition?.replaceAll('"','&quot;')}">${word}</span>`
        }))
      }
      if (task.type=='gaps') {
        let i = 0
        const regexGaps = this.formRegex(task.options)
        task.text = task.text.replaceAll(regexGaps, _ => {
          return `<label>
            <input class='gap' type='radio' name='a' ${this.isCompleted ? 'disabled' : ''}>
            <span class='gap-label'>${this.isCompleted ? task.options[task.answer[0][i++]] : ''}</span>
          </label>`
        })
      }
    }
    catch(err) {
      console.log(err)
      store.commit('updateState', { isBrowserCompatible: false })
    }

    this.text = task.text

  }
  
  get isAnswered() {
    return this.answer?.every(e => e?.at(0) != undefined)
  }

  formRegex(arr) {
    const regexpString = `(?<=[\\s,.:;"'> ]|^)(${arr.filter(e => !!e).join('|')})(?=[\\s,.:;"'< ]|$)`
    return new RegExp(regexpString, 'gi')
  }

  deepReplace(obj, keyName, replacer) {
    if (typeof obj !== "object" || obj === null) return
    Object.entries(obj).forEach(([k,v]) => {
      if (Array.isArray(v)) {
        obj[k].forEach(e => this.deepReplace(e, keyName, replacer))
      } 
      else if (typeof v === "object" && v !== null) {
        this.deepReplace(obj[k], keyName, replacer)
      }
      else if (typeof v === "string" && k === keyName) {
        obj[k] = replacer(v)
      } 
    })
  }

}
<template>
<div>
  <slot/>
</div>
</template>

<script>
export default {
  name: 'accordion-content'
}
</script>
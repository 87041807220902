<template>
<div class="v-tabs-content">
  <keep-alive>
    <slot/>
  </keep-alive>
</div>
</template>

<script>
export default {
  name: 'v-tabs-content'
}
</script>

<style>
.v-tabs-content {
  position: relative;
}
</style>
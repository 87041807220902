export default {

  light: {
    '--border-radius': '.625em',
    '--color-background': '#fff',
    '--color-text': '#000',
  },

  dark: {
    '--border-radius': '.625em',
    '--color-background': '#333',
    '--color-text': '#fff',
  },

}
<template>
<div class="v-input-file"
  :class="{ disabled }">

  <div class="v-input-file__label"
    v-if="label">
    {{ label }}
  </div>

  <label v-if="!file?.size">
    <input class="v-input-file__input"
      ref="input"
      type="file"
      v-bind="{ accept, disabled }"
      @change="inputFile($event)">
    <slot>
      <div class="button-pale v-input-file__input-label"
        :style="{ color: $cmain }">
        <i class="material-symbols v-input-file__icon">attach_file</i>
        <span class="v-input-file__text">
          {{ labelUpload }}
        </span>
      </div>
    </slot>
  </label>

  <div class="v-input-file__input-error"
    v-if="v$.file.$error">
    Ви маєте обрати файл для завантаження
  </div>

  <div class="v-input-file__label"
    v-if="file?.size">
    {{ file.name }}
    <i class="material-symbols-outlined v-input-file__icon"
      style="color:red"
      @click="file=null">
      delete
    </i>
    <span class="v-input-file__spinner"
      v-loading.small="uploadURL && isUploading">
    </span>
  </div>

</div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { requiredIf } from '@vuelidate/validators'
export default {
  emits: [
    'changed',
    'uploading',
    'uploaded',
  ],
  props: {
    required: { type: Boolean, required: false, default: false },
    accept: { type: String, required: false, default: '*' },
    disabled: { type: Boolean, required: false, default: false },
    maxSize: { type: Number, required: false, default: 250 },
    fileName: { type: String, required: false, default: undefined },
    uploadURL: { type: String, required: false, default: undefined },
    formFields: { type: Object, required: false, default: undefined },
    label: { type: String, required: false, default: undefined },
    labelUpload: { type: String, required: false, default: "Завантажити файл" },
    labelDelete: { type: String, required: false, default: undefined },
  },
  data() {
    return {
      v$: useVuelidate(),
      isUploading: false,
      form: new FormData(),
      file: null,
    }
  },
  validations() {
    return {
      file: {
        name: { required: requiredIf(_ => this.required) }
      },
    }
  },
  methods: {
    click() {
      this.$refs.input.click()
    },
    async inputFile(event) {
      this.file = event.target.files[0]
      if (this.file.size > this.maxSize*1024*1024) return this.$popupError(`Розмір файла не має перевищувати ${this.maxSize}Мб`)
      this.$emit('changed', this.file)
      if (this.uploadURL) {
        this.uploadFile()
      }
    },
    async uploadFile() {
      this.isUploading = true
      this.$emit('uploading')
      if (typeof this.formFields == 'object') {
        Object.entries(this.formFields).forEach(([k,v]) => this.form.append(k, v))
      }
      this.form.append('file', this.file, this.fileName)
      const res = await this.$api.post(this.uploadURL, this.form, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      this.$emit('uploaded', res.data)
      this.isUploading = false
      this.form = new FormData()
      this.file = null
    },
  },
}
</script>

<style lang="scss" scoped>
.v-input-file {
  &__label {
    margin-bottom: .75em;
    display: flex;
    align-items: center;
    gap: .7em;
    font-size: 1em;
    text-align: left;
    line-height: 1.5;
    color: #0008;
  }
  &__input {
    display: none;
  }
  &__input-label {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: .5em;
    &:hover { opacity: .75; }
  }
  &__icon {
    font-size: 1.25em;
    font-weight: normal;
    vertical-align: middle;
  }
  &__text {
    vertical-align: middle;
  }
  &__error {
    margin-top: .5em;
    font-size: .85em;
    font-weight: 500;
    color: $red;
  }
  &__spinner {
    display: inline-block;
    width: 2em;
  }
}
</style>
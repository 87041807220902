<template>
  <div class="v-link"
    @click="click()">
    <v-button-animated class="v-link__icon"
      v-bind="{ icon, color, direction }"
      ref="button">
    </v-button-animated>
    <span class="v-link__label">
      {{ label }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    route: { type: [Object, String], required: false, default: undefined },
    icon: { type: String, required: false, default: undefined },
    label: { type: String, required: false, default: undefined },
    color: { type: String, required: false, default: '#333' },
    direction: { type: String, required: false, default: 'left' },
  },
  methods: {
    click() {
      this.$refs.button.click()
      if (this.route) this.$router.push(this.route)
    }
  },
}
</script>

<style lang="scss" scoped>
.v-link {
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: .7em;
  text-decoration: none;
  color: v-bind(color);
  border-bottom: 1px solid transparent;
  line-height: 1.2;
  font-size: inherit;
  &__icon {
    cursor: pointer;
    font-size: 1.5em;
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 1.2em;
      height: 1.2em;
      transform: translate(-50%,-50%);
      background-color: v-bind(color);
      border-radius: 50%;
      opacity: .1;
    }
  }
  &__label {
    border-bottom: 1px solid transparent;
    &:hover {
      border-color: v-bind(color);
    }
  }
}
</style>  
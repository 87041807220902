<template>
<div class="v-pagination-item">

  <span class="v-pagination-item__line" 
    v-if="hasLines && page!=pageMin" 
    style="left:0">
  </span>

  <span class="v-pagination-item__line"
    style="right:0"
    v-if="hasLines && page!=pageMax">
  </span>

  <div class="v-pagination-item__input"
    :class="{ [shape]: true, selected: isActive }">
    <b class="v-pagination-item__label">
      {{ page }}
    </b>
    <div class="tag v-pagination-item__tag"
      v-if="tag">
      {{ tag }}
    </div>
  </div>

</div>
</template>

<script>
export default {
  props: {
    isActive: { type: Boolean, required: false, default: false },
    hasLines: { type: Boolean, required: false, default: false },
    page: { type: Number, required: true, default: 1 },
    pageMin: { type: Number, required: false, default: 1 },
    pageMax: { type: Number, required: true, default: 1 },
    color: { type: String, required: false, default: '#c7c7c7' },
    size: { type: String, required: false, default: '3em' },
    shape: { type: String, required: false, default: 'circle' },
    tag: { type: [Number, String], required: false, default: undefined },
    tagColor: { type: [Number, String], required: false, default: undefined },
  },
}
</script>

<style lang="scss" scoped>
.v-pagination-item {
  display: inline-block;
  position: relative;
  width: 100%;
  height: v-bind(size);
  -webkit-tap-highlight-color: transparent;
  &__input {
    position: relative;
    margin: 0;
    pointer-events: initial;
    cursor: pointer;
    appearance: none;
    width: v-bind(size);
    aspect-ratio: 1;
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%,-50%) scale(1);
    border: 2px solid v-bind(color);
    &::after {
      content: '';
      position: absolute;
      top: 50%; left: 50%;
      transform: translate(-50%,-50%) scale(0);
      width: v-bind(size);
      aspect-ratio: 1;
      opacity: 0;
      border-radius: 50%;
      transition: .4s ease-out;
    }
    &.circle { border-radius: 50%; }
    &.square { border-radius: 25%; }
    &.rombus {
      border-radius: 33%;
      transform: rotate(45deg);
    }
    &.selected {
      border: none;
      b { color: #fff !important; }
      &::after {
        transform: translate(-50%,-50%) scale(1);
        opacity: 1;
        background: v-bind(color);
      }
    }
  }
  &__tag {
    z-index: 2;
    position: absolute;
    right: 0;
    bottom: 0;
    padding: .15em .5em;
    transform: translate(50%,25%);
    background-color: v-bind(tagColor);
  }
  &__label {
    z-index: 1;
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%,-50%);
    font-size: 1.5em;
    font-weight: 700;
    transition: .4s ease-out;
    color: v-bind(color);
  }
  &__line {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: calc((100% - v-bind(size)) / 2);
    height: .15em;
    background: v-bind(color);
  }
}
</style>
import { createApp } from 'vue'
import VModal from './v-modal.vue'
import ModalInfo from './modal-info.vue'
import ModalConfirm from './modal-confirm.vue'
import ModalDictionary from './modal-dictionary.vue'
import ModalNotification from './modal-notification.vue'

const close = function(parentEl, app) {
  parentEl?.parentNode?.removeChild(parentEl)
  app.unmount()
  app = null
}

const popup = function(component, data) {
  const el = document.createElement('div')
  el.classList.add('v-modal__slot')
  document.body.appendChild(el)
  const popup = createApp(
    { extends: component }, 
    {
      ...data,
      modelValue: true,
      onClose: _ => close(el, popup),
    }
  )
  popup.mount(el)
}

const popupInfo = (message, title, icon, color, action) => popup(ModalInfo, { message, title, icon, color, action })
const popupError = (message, title='Помилка', icon="close", action) => popupInfo(message, title, icon, "#ff4d4d", action)
const popupSuccess = (message, title='Успішно', icon="done") => popupInfo(message, title, icon, "#4da64d")

const popupDictionary = payload => popup(ModalDictionary, payload)
const popupNotification = payload => popup(ModalNotification, payload)

const popupConfirm = function(title, message, labelConfirm='Так', labelDiscard='Скасувати',) {
  const el = document.createElement('div')
  document.body.appendChild(el)
  let popup = createApp(
    { extends: ModalConfirm }, 
    {
      modelValue: true,
      title,
      message,
      onClose: function() { close(el, popup) }
    }
  )
  const popupRoot = popup.mount(el)
  popupRoot.promise.then(res => close(el, popup))
  return popupRoot.promise
}

export default {
  popupInfo,
  popupSuccess,
  popupError,
  popupConfirm,
  popupNotification,
  install: app => {
    app.component('vModal', VModal)
    app.config.globalProperties.$popupInfo = popupInfo
    app.config.globalProperties.$popupSuccess = popupSuccess
    app.config.globalProperties.$popupError = popupError
    app.config.globalProperties.$popupDictionary = popupDictionary
    app.config.globalProperties.$popupConfirm = popupConfirm
    app.config.globalProperties.$popupNotification = popupNotification
  }
}
const plugCreate = (el, binding) => {
  let plug = document.createElement('div')
  plug.classList.add('plug')
  const plugContent = document.createElement('div')
  el.appendChild(plug)
  plug.appendChild(plugContent)
  plugContent.innerHTML = binding.value
  Object.assign(plug.style, {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    minHeight: binding.arg,
    userSelect: 'text',
    padding: '.5em 1.5em',
    color: '#0006',
    lineHeight: 1.5,
  })
}

export default {

  mounted: (el, binding) => {
    if (!el.children.length) plugCreate(el, binding)
  },

  updated: (el, binding) => {
    if (!el.children.length) plugCreate(el, binding)
    else if (el.children.length > 1) el.querySelector(':scope > .plug')?.remove()
  }

}
export default class Ring extends Array {

  get(i) { 
    if (i<0) return this[Math.abs(this.length + i) % this.length]
    else return this[i % this.length] 
  }

  slice(i1, i2) {
    let arr = []
    for(let i = i1; i <= i2; i++) {
      arr.push(this.get(i))
    }
    return arr
  }

}
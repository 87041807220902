<template>
<div class="v-viewer"
  v-loading.large="isLoading">

  <iframe class="v-viewer__iframe"
    :src="ext=='pdf' ? src : urlFull"
    @load="onLoad()">
  </iframe>

  <div class="v-viewer__toolbar"
    v-if="ext=='pdf'">
    <button class="material-symbols button-icon"
      @click="$download(blob, 'application/pdf', name)">
      download
    </button>
    <button class="material-symbols button-icon"
      @click="$navigateLink(urlFull, { isBlank: true })">
      open_in_new
    </button>
  </div>

</div>
</template>

<script>
export default {
  emits: [
    'ready',
  ],
  props: {
    url: { type: String, required: true, default: undefined },
    ext: { type: String, required: true, default: undefined },
    name: { type: String, required: true, default: undefined },
  },
  async mounted() {
    this.isLoading = true
    await this.loadFile()
    this.aspectRatio = this.ext=='pptx' ? '1.78' : '0.75'
  },
  data() {
    return {
      isLoading: false,
      src: undefined,
      aspectRatio: null,
      blob: {},
      pdf: {},
    }
  },
  computed: {
    urlFull() {
      const url = `${this.$s3}/${this.$subjectId}/${this.url}` ?? ''
      if (this.ext=='pdf') return `${url}?size=pdf#view=fit&navpanes=0&scrollbar=0`
      if (this.ext=='docx' || this.ext=='pptx' || this.ext=='xlsx') return `https://docs.google.com/gview?url=${url}&embedded=true`
      //if (this.ext=='docx' || this.ext=='pptx' || this.ext=='xlsx') return `https://view.officeapps.live.com/op/embed.aspx?src=${url}`
      return url
    }
  },
  methods: {
    onLoad() {
      this.$emit('ready')
      this.isLoading = false
    },
    async loadFile() {
      if (this.ext != 'pdf') return
      const res = await fetch(this.urlFull)
      this.blob = await res.blob()
      this.src = URL.createObjectURL(this.blob)
    },
  },
}
</script>

<style lang="scss" scoped>
.v-viewer {
  &__iframe {
    display: block;
    width: 100%;
    height: 100%;
    aspect-ratio: v-bind('aspectRatio');
    border: none;
  }
  &__toolbar {
    margin-top: 1em;
    display: flex;
    gap: 1em;
  }
}
</style>
<template>
<div class="v-button-save">

  <button class="button-primary v-button-save__button"
    v-if="isSaved===undefined || hasError"
    :disabled="!isSavePossible"
    @click="click()">
    <span class="v-button-save__loader"
      v-if="isLoading">
    </span>
    {{ label ?? "Зберегти" }}
  </button>

  <div class="button-primary v-button-save__button"
    v-else
    :style="{ background: isSaved ? $green : $red }">
    <i class="material-symbols">task_alt</i>
    {{ isSaved ? 'Збережено' : 'Не збережено' }}
  </div>

  <div class="text-error v-button-save__error"
    v-if="hasError">
    Будь ласка, перевірте введені дані і виправте помилки
  </div>

</div>
</template>

<script>
export default {
  props: {
    isActive: { type: Boolean, required: false, default: false },
    hasError: { type: Boolean, required: false, default: false },
    modelValue: { type: Object, required: false, default: undefined },
    action: { type: Function, required: false, default: false },
    label: { type: String, required: false, default: undefined },
  },
  created() {
    this.$nextTick(_ => this.isSavePossible = this.isActive)
  },
  data() {
    return {
      isSavePossible: false,
      isLoading: false,
      isSaved: undefined,
    }
  },
  methods: {
    click() {
      this.$emit('click')
      if (this.hasError) return
      this.$nextTick(async _ => {
        this.isLoading = true
        const res = await this.action()
        if (res?.status && res.status!=200 && res.status!=202) {
          this.isSaved = false
          return
        }
        this.isSavePossible = false
        this.isLoading = false
        this.isSaved = true
      })
    },
  },
  watch: {
    modelValue: {
      deep: true,
      handler() {
        this.isSavePossible = true
        this.isSaved = undefined
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.v-button-save {
  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .7em;
    width: 100%;
    height: 3.25em;
    i { font-size: 1.25em; }
  }
  &__loader {
    width: 1.25em;
    height: 1.25em;
    border: 3px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  &__error {
    margin-top: .5em;
  }
  @keyframes rotation {
    0% { transform: rotate(0deg) }
    100% { transform: rotate(360deg) }
  }
}
</style>
<template>
<div class="v-input-color">

  <div class="v-input-color__label">
    {{ label }}
  </div>
  <input class="v-input-color__input"
    type="color"
    list="colors"
    :value="modelValue"
    @input="event => $emit('update:modelValue', event.target.value)">
  <datalist id="colors">
    <option
      v-for="color in colors"
      :value="color">
    </option>
  </datalist>

</div>
</template>

<script>
export default {
  props: {
    modelValue: { type: [Number, String], required: false, default: undefined },
    label: { type: String, required: false, default: undefined },
    colors: { type: Array, required: false, default: [] },
  },
}
</script>

<style lang="scss" scoped>
.v-input-color {
  &__label {
    margin-bottom: .75em;
    line-height: 1.5;
    color: #0008;
  }
  &__input {
    width: 100%;
  }
}
</style>
<template>
<div>
  <slot/>
</div>
</template>

<script>
export default {
  name: 'accordion-header'
}
</script>

<style lang="scss" scoped>
div {
  position: relative;
}
</style>
<template>
<div class="v-input-range">

  <div class="v-input-range__label"
    v-if="label">
    {{ label }}
  </div>

  <div class="v-input-range__body">

    <label style="position:relative">
      <input class="v-input-range__input"
        :id="$uuid"
        type="range"
        ref="input"
        :value="modelValue"
        v-bind="{ min, max, step, disabled }"
        @focus="input()"
        @input="input()">
      <div class="v-input-range__thumb"
        :style="{ left: percentage + '%' }">
        {{ modelValue }}
      </div>
    </label>

    <div class="v-input-range__ticks"
      v-if="ticks">
      <span class="v-input-range__tick"
        v-for="i in +ticks"
        :style="{ left: (i-1) * 100 / (+ticks-1) + '%' }">
      </span>
    </div>

  </div>

</div>
</template>

<script>
export default {
  props: {
    modelValue: { type: [Number, String], required: false, default: undefined },
    label: { type: String, required: false, default: undefined },
    min: { type: [Number, String], required: false, default: 0 },
    max: { type: [Number, String], required: false, default: 10 },
    step: { type: [Number, String], required: false, default: 1 },
    ticks: { type: [Number, String], required: false, default: undefined },
    disabled: { type: Boolean, required: false, default: false },
  },
  mounted() {
    this.$nextTick(_ => this.input())
  },
  computed: {
    percentage() {
      return (this.modelValue - this.min) / (this.max - this.min) * 100
    }
  },
  methods: {
    input() {
      const el = this.$refs.input
      if (this.max != this.min) el.style.background = `linear-gradient(to right, #433298 0%, #433298 ${this.percentage}%, #0003 ${this.percentage}%, #0003 100%)`
      else el.style.background = 'none'
      this.$emit('update:modelValue', Number(el.value))
    },
  },
  watch: {
    modelValue() {
      this.$nextTick(_ => this.input())
    },
    max() {
      this.$nextTick(_ => this.$refs.input.focus())
    }
  }
}
</script>

<style lang="scss" scoped>
input[type="range"]::-webkit-slider-thumb {
  opacity: 0;
}
.v-input-range {
  position: relative;
  &__label {
    margin-bottom: .75em;
    line-height: 1.5;
    color: #0008;
  }
  &__note {
    margin-top: .7em;
    color: #0008;
  }
  &__body {
    padding: 0 .75em;
  }
  &__input {
    cursor: pointer;
    appearance: none;
    margin: 0 -.75em;
    width: calc(100% + 1.5em);
    height: 4px;
    border-radius: 2px;
    &:focus { outline: none; }
    &:disabled {
      cursor: unset;
      opacity: .5;
    }
  }
  &__thumb {
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 1.75em;
    height: 1.75em;
    line-height: 1.75;
    font-weight: 500;
    background-color: $cmain;
    border-radius: 50%;
    color: #fff;
    text-align: center;
  }
  &__ticks {
    position: relative;
  }
  &__tick {
    position: absolute;
    width: 1px;
    height: 4px;
    background-color: #333;
  }
}
</style>
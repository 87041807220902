<template>
<div class="v-toolbar">
  <div v-for="item in itemsDisplayed">
    <button class="material-symbols-outlined v-toolbar__icon"
      :disabled="item.disabled ?? false"
      :style="item.style"
      @click="item.action()">
      {{ item.icon }}
    </button>
  </div>
</div>
</template>

<script>
export default {
  props: {
    items: { type: Array, required: true, default: [] },
  },
  computed: {
    itemsDisplayed() {
      return this.items.filter(e => e.visible)
    },
  }
}
</script>

<style lang="scss" scoped>
.v-toolbar {
  display: flex;
  align-items: center;
  gap: .35em;
  &__icon {
    cursor: pointer;
    display: block;
    padding: 0;
    font-size: 1.5em;
    color: #555;
    &:hover { opacity: .85; }
    &:active { opacity: .5; }
    &:disabled {
      cursor: unset;
      opacity: .25;
    }
  }
}
</style>
<template>
<div  class='carousel-wrapper' ref='wrap'>

  <button class="carousel-btn-next carousel-button material-symbols" 
    @click='cardSelect(cardCurrentIndex + 1)'
    :disabled='slidesTotal==1'
    :style='{ left: arrowsPosition=="inside" ? "95%" : "105%", ...arrowStyle }'>
    {{ arrowIcon }}
  </button>
  <button class="carousel-btn-prev carousel-button material-symbols" 
    @click='cardSelect(cardCurrentIndex - 1)'
    :disabled='slidesTotal==1'
    :style='{ left: arrowsPosition=="inside" ? "5%" : "-5%", ...arrowStyle }'>
    {{ arrowIcon }}
  </button>

  <div class="carousel-body-wrapper" 
    v-if='slidesTotal>1'>
    <div class="carousel-body" 
      @transitionend.self='cardSetSelected()'
      ref='wrapper'>
      <div class="carousel-card-wrapper"
        v-for='i in slidesShown + 2'>
        <div class="carousel-card"
          :style='{ width: cardWidth, padding: `30px ${slideMargin}px` }'
          ref='card'>
          <slot :name='`slide${cardIndicesRange[i-1]}`'/>
        </div>
      </div>
    </div>
  </div>

  <div v-else>
    <div class="carousel-card"
    :style='{ padding: `30px ${slideMargin}px` }'>
      <slot name="slide0"></slot>
    </div>
  </div>

  <div class="carousel-crumbs">
    <span class="carousel-crumb"
      v-for='i in slidesTotal' 
      :style='{ background: i-1==cardIndicesRange[n] ? "#000" : "#0004" }'>
    </span>
  </div>

</div>


</template>

<script>
import Ring from '@/classes/Ring'
export default {
  props: {
    slidesShown: { type: Number, required: false, default: 3 },
    slidesTotal: { type: Number, required: true },
    slideFirst: { type: Number, required: false, default: 0 },
    slideMargin: { type: Number, required: false, default: 0 },
    slideSecondaryAngle: { type: Number, required: false, default: 0 },
    slideSecondarySize: { type: Number, required: false, default: 1 },
    arrowIcon: { type: String, required: false, default: 'arrow_forward' },
    arrowStyle: { type: Object, required: false, default: {} },
    arrowsPosition: { 
      type: String, required: false, default: 'inside', 
      validator(val) { return ['inside', 'outside'].includes(val) } 
    },
  },
  data() { return {
    buttonsActive: true,
    cardWidth: 0,
    ring: undefined,
    cardIndicesRange: [],
    cardCurrentIndex: undefined,
    n: (this.slidesShown % 2 == 1) ? (this.slidesShown+1)/2 : this.slidesShown/2+1,
  }},
  mounted() {
    if(this.slidesTotal > 1) { this.$nextTick(_ => {
      this.cardWidth = `${Math.round(this.$refs.wrap.clientWidth / this.slidesShown)}px`
      this.ring = new Ring(...Array.from(Array(this.slidesTotal).keys()))
      this.cardCurrentIndex = this.slideFirst
      this.cardSetSelected()
      window.addEventListener('resize', this.onResize)
    })
  }
  },
  methods: {
    onResize() {
      const w = this.$refs.wrap?.clientWidth ?? 0
      this.cardWidth = `${Math.round(w / this.slidesShown)}px`
    },
    cardSelect(cardIndex) {
      if(this.buttonsActive) {
        const dif = cardIndex - this.cardCurrentIndex
        const n = (this.slidesShown + 1) / 2
        if (dif == 1 || dif == (this.slidesTotal>2)*(-this.slidesTotal+1)) {
          this.$refs.wrapper.style.transition = '.35s'
          this.$refs.wrapper.style.transform = `translateX(calc(-1 * ${this.cardWidth}))`
          this.$refs.card.forEach((e,i) => {
            e.style.transition = `.35s`
            e.style.transform = 
              (this.slideSecondaryAngle ? `rotate3d(0, 1, 0, ${-this.slideSecondaryAngle*(i-n-1)}deg)` : '')
              + (this.slideSecondarySize ? ` scale(${i == n+1 ? 1 : this.slideSecondarySize})` : '')
          })
        }
        else if (dif == -1 || dif == (this.slidesTotal>2)*(this.slidesTotal-1)) {
          this.$refs.wrapper.style.transition = '.35s'
          this.$refs.wrapper.style.transform = `translateX(${this.cardWidth})`
          this.$refs.card.forEach((e,i) => {
            e.style.transition = `.35s`
            e.style.transform = 
              (this.slideSecondaryAngle ? `rotate3d(0,1,0, ${-this.slideSecondaryAngle*(i-n+1)}deg)` : '')
              + (this.slideSecondarySize ? ` scale(${i == n-1 ? 1 : this.slideSecondarySize})` : '')
          })
        }
        this.cardCurrentIndex = cardIndex
        this.buttonsActive = false
      }
    },
    cardSetSelected() {
      this.buttonsActive = true
      const N = (this.slidesShown + 1) / 2
      this.cardIndicesRange = this.ring.slice(this.cardCurrentIndex - this.n, this.cardCurrentIndex + this.n)
      this.$refs.wrapper.style.transition = '0s'
      this.$refs.wrapper.style.transform = 'none'
      this.$refs.card.forEach((e,i) => {
        e.style.transition = '0s'
        e.style.transform = 
          (this.slideSecondaryAngle ? `rotate3d(0,1,0, ${-this.slideSecondaryAngle*(i-N)}deg)` : '')
          + (this.slideSecondarySize ? ` scale(${i==N ? 1 : this.slideSecondarySize})` : '')
      }) 
    },
  }
}
</script>

<style lang="scss" scoped>
.carousel-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}
.carousel-controls {
  display: flex;
  justify-content: space-between;
}
.carousel-body-wrapper {
  display: flex;
  justify-content: center;
  overflow-x: hidden;
  scrollbar-width: none;
  &::-webkit-scrollbar { display: none; }
}
.carousel-body {
  white-space: nowrap;
}
.carousel-card-wrapper {
  position: relative;
  height: 100%;
  display: inline-block;
  perspective: 500px;
  transition: 1s;
}
.carousel-card {
  white-space: initial;
  height: 100%;
  display: flex;
  justify-content: center;
  -webkit-backface-visibility: hidden;
}
.carousel-button {
  cursor: pointer;
  z-index: 3;
  position: absolute;
  font-size: 2em;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  background: #0004;
  color: #fff;
  &:hover { opacity: .7; }
  &:active { opacity: .6 }
  &:disabled { opacity: .25 }
}
.carousel-btn-next {
  top: 50%;
  transform: translate(-100%,-50%);
}
.carousel-btn-prev {
  top: 50%;
  transform: translate(0%,-50%) rotate(180deg);
}
.carousel-crumbs {
  z-index: 3;
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%,-50%);
}
.carousel-crumb {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 5px;
  border-radius: 50%;
  background: #0004;
}
</style>
<template>
<div class="v-input-icon">

  <div class="v-input-icon__body"
    v-click-outside="_ => isUnfolded = false">

    <div class="material-symbols v-input-icon__title"
      ref="title"
      @click="toggle()"
      :class="{ pale: !modelValue }">
      {{ modelValue ?? 'add_reaction' }}
    </div>

    <div class="v-input-icon__icons-wrapper"
      v-if="isUnfolded">
      <div class="v-input-icon__icons"
        ref="icons">
        <div class="material-symbols v-input-icon__icon"
          v-for="icon in icons"
          @click="select(icon)">
          {{ icon }}
        </div>
      </div>
    </div>

  </div>

  <div class="v-input-icon__label"
    v-if="label">
    <div>{{ label }}</div>
    <i>{{ modelValue ?? 'Не обрано' }}</i>
  </div>

</div>
</template>

<script>
export default {
  props: {
    modelValue: { type: String, required: false, default: undefined },
    label: { type: String, required: false, default: 'Іконка' },
    columns: { type: [String, Number], required: false, default: 6 },
    height: { type: String, required: false, default: 'auto' },
    size: { type: String, required: false, default: '2em' },
  },
  data() {
    return {
      isUnfolded: false,
      icons: [
        "notifications", "notifications_active", "notifications_none", "notifications_off", "group", "groups", "group_add", "group_work", "person", "person_add", "person_remove", "supervisor_account", "admin_panel_settings", "account_circle", "account_box", "add_alert", "alarm", "alarm_add", "alarm_on", "alarm_off", "warning", "error", "priority_high", "info", "report", "report_problem", "help", "announcement", "event", "event_available", "event_busy", "event_note", "sms", "sms_failed", "chat", "chat_bubble", "feedback", "mark_email_read", "mark_email_unread", "mark_chat_read", "mark_chat_unread", "history", "history_edu", "recent_actors", "update", "sync", "sync_problem", "sync_disabled", "autorenew", "cached", "schedule", "schedule_send", "calendar_today", "date_range", "timer", "timer_off", "task", "assignment", "assignment_turned_in", "assignment_late", "assignment_return", "assignment_returned", "done", "done_all", "done_outline", "pending", "pending_actions", "new_releases", "flag", "push_pin", "check_circle", "radio_button_checked", "radio_button_unchecked", "circle_notifications", "broadcast_on_home", "broadcast_on_personal", "support_agent", "connect_without_contact", "person_search", "do_not_disturb", "do_not_disturb_on", "event_repeat", "person_pin", "person_pin_circle", "badge", "card_membership", "trophy"
      ],
    }
  },
  methods: {
    select(val) {
      this.$emit("update:modelValue", val)
      this.isUnfolded = false
    },
    toggle() {
      this.isUnfolded = !this.isUnfolded
      this.$nextTick(_ => {
        if (this.$refs.icons) {
          this.$refs.icons.style.top = `${this.$refs.title.offsetHeight + 5}px`
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
$columns: v-bind(columns);
$size: v-bind(size);
$gap: .25em;

.v-input-icon {
  display: flex;
  align-items: center;
  gap: 1em;
  &__label {
    line-height: 1.5;
    color: #0008;
  }
  &__body {
    z-index: 10;
    position: relative;
    border-radius: .5em;
    border: 1px solid #0004;
  }
  &__title {
    cursor: pointer;
    position: relative;
    aspect-ratio: 1;
    padding: 0 .25em;
    font-size: 1.65em;
    line-height: 1.5;
    text-align: center;
    &:hover { opacity: .75; }
    &:active { opacity: .5; }
    &.pale { opacity: .25; }
  }
  &__icons-wrapper {
    position: absolute;
    left: 0;
    padding: .5em;
    box-shadow: 0 2px 16px #0003;
    background: #fff;
    border: 1px solid #0004;
    border-radius: .5em;
  }
  &__icons {
    display: flex;
    flex-wrap: wrap;
    gap: $gap;
    width: calc(calc($size + $gap) * $columns);
    max-height: v-bind(height);
    overflow-y: auto;
    scrollbar-width: none;
    &::-webkit-scrollbar { display: none; }
  }
  &__icon {
    cursor: pointer;
    font-size: $size;
    border-radius: .25em;
    &:hover {
      background-color: #0001;
    }
  }
}
</style>
<template>
<v-modal width="28em"
  :modelValue="modelValue"
  @close="$emit('update:modelValue', false)">
  <template #content>
    <div v-if="title" 
      class="v-modal__title"
      style="text-align:center">
      {{ title }}
    </div>
    <div v-if="message" 
      class="v-modal__text"
      v-html="message">
    </div>
    <table>
      <thead>
        <tr>
          <th v-for="label in labels">
            {{ label }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="[k,v] in data">
          <td>{{ k }}</td>
          <td>{{ v }}</td>
        </tr>
      </tbody>
    </table>
  </template>
</v-modal>
</template>

<script>
import vModal from './v-modal.vue'
export default {
  components: { vModal },
  props: {
    modelValue: { type: Boolean, required: false, default: false },
    message: { type: String, required: false, default: undefined },
    title: { type: String, required: false, default: undefined },
    labels: { type: Array, required: false, default: [] },
    data: { type: Array, required: false, default: [[]] },
  }
}
</script>
<template>
<div class="v-validated">
  <slot/>
</div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
export default {
  data() {
    return {
      v$: useVuelidate(),
    }
  },
}
</script>